import React, { useState, useEffect } from 'react';
import { useDeepCompareEffectNoCheck as useEffectDeepCompare } from 'use-deep-compare-effect';
import {
  Box,
  Button,
  CheckBox as CheckBoxGrommet,
  Form,
  FormField,
  TextInput,
  ThemeContext,
} from 'grommet';
import { Share } from 'grommet-icons';
import PropTypes from 'prop-types';
import Ajv from 'ajv';

const jsonSchemaValidator = new Ajv();

// A question with multiple statements to (multiply) select, including an other field.
// The other field has suggestions, which will update as the prop changes
export const CheckBox = (props) => {
  // MARK - Form handling

  const [validator, setValidator] = useState((state) => () => false);

  useEffect(() => {
    const schema = {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'string',
        minLength: 1,
      },
    };
    if (props.minSelected !== undefined) {
      schema.minItems = props.minSelected;
    }
    if (props.maxSelected !== undefined) {
      schema.maxItems = props.maxSelected;
    }
    setValidator((state) => jsonSchemaValidator.compile(schema));
  }, [props.minSelected, props.maxSelected]);

  const [values, setValues] = useState([]); // Entries-like array, ordered as-per props.choices
  const [otherTextValues, setOtherTextValues] = useState({});

  useEffectDeepCompare(() => {
    const choiceKeys = Array.from(
      { length: props.choices.length },
      (_, idx) => `${props.id}-choice${idx}`
    );
    const otherKeys = Array.from(
      { length: props.other },
      (_, idx) => `${props.id}-other${idx}`
    );
    setValues([...choiceKeys, ...otherKeys].map((key) => [key, false]));
    setOtherTextValues(
      Object.fromEntries(otherKeys.map((key) => [`${key}-text`, '']))
    );
  }, [props.id, props.choices, props.other]);

  useEffectDeepCompare(() => {
    // Only set values when we have a submit value. e.g. question content will be set again with a broadcast live data update.
    if (props.submit === undefined) {
      return;
    }
    setValues((values) => {
      const previousSubmitValues = validator(props.submit)
        ? [...props.submit]
        : [];
      const choiceValues = values
        .filter(([key, value]) => key.includes('choice'))
        .map(([key, value], idx) => {
          const previousIdx = previousSubmitValues.indexOf(props.choices[idx]);
          if (previousIdx === -1) {
            return [key, false];
          }
          previousSubmitValues.splice(previousIdx, 1);
          return [key, true];
        });
      const otherValues = values
        .filter(([key, value]) => key.includes('other'))
        .map(([key, value]) => {
          const textValue = previousSubmitValues.pop();
          return [key, textValue !== undefined];
        });
      return [...choiceValues, ...otherValues];
    });
    setOtherTextValues((values) => {
      const previousSubmitValues = validator(props.submit)
        ? [...props.submit]
        : [];
      const otherSubmitValues = previousSubmitValues
        .filter((x) => !props.choices.includes(x))
        .values();
      return Object.fromEntries(
        Object.keys(values).map((key) => [
          key,
          otherSubmitValues.next().value || '',
        ])
      );
    });
  }, [props.choices, props.submit, validator]);

  const [errorMessage, setErrorMessage] = useState('');

  useEffectDeepCompare(() => {
    // Transform form-centric values
    //    {choice0: true, choice1: true, choice2: true, choice3: false, choice4: false, …}
    // into data-centric array of checked checkboxkeys
    //    ["choice0", "choice1", "choice2"]
    const checked = [];
    values.forEach(([key, value]) => {
      if (value === true) {
        const match = /\d+-(\D+)(\d+)/.exec(key);
        if (match[1] === 'choice') {
          checked.push(props.choices[match[2]]);
        }
        if (match[1] === 'other') {
          checked.push(otherTextValues[`${key}-text`]);
        }
      }
    });

    // Validate
    const isValid = validator(checked);

    // Pass up to App
    props.onValid(isValid);

    // Act on validation
    // Ideally, this would only display an error if a form field is touched.
    if (isValid) {
      props.onData(checked);
      setErrorMessage('');
    } else {
      const messages = validator.errors
        ? validator.errors.map((error) => {
            if (
              error.schemaPath === '#/minItems' ||
              error.schemaPath === '#/maxItems'
            ) {
              return `Please select within the required range`;
            }
            if (error.schemaPath === '#/items/minLength') {
              return 'Please enter your other choice';
            }
            console.warn('Unhandled validation error: ', error);
            return error.message;
          })
        : ['Failed validation'];
      setErrorMessage(messages.join(' · '));
    }
  }, [values, otherTextValues, props.choices, validator]);

  const onChange = (event) => {
    const {
      target: { name, checked },
    } = event;
    setValues((values) =>
      values.map((pair) => (pair[0] === name ? [name, checked] : pair))
    );
    console.log('onChange ', name, checked);
  };

  // MARK - 'Other' text input field handling

  const onTextInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setOtherTextValues((values) => ({ ...values, [name]: value }));
    setValues((values) => {
      const key = name.replace('-text', '');
      return values.map((pair) =>
        pair[0] === key ? [key, value.length > 0] : pair
      );
    });
    console.log('onTextInputChange ', values, name, value);
  };

  const onTextInputSelect = (event) => {
    const {
      suggestion,
      target: { name },
    } = event;
    setOtherTextValues((values) => ({ ...values, [name]: suggestion }));
  };

  const [otherSuggestions, setOtherSuggestions] = useState([]);

  // Keep suggestions updated from source and filtered from entered text
  useEffect(() => {
    const newSuggestions = Object.entries(otherTextValues).reduce(
      (acc, [key, value]) => {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const exp = new RegExp(escapedText, 'i');
        const allSuggestions = props.otherSuggestions || [];
        acc[key] = allSuggestions.filter((s) => exp.test(s));
        return acc;
      },
      {}
    );
    setOtherSuggestions(newSuggestions);
  }, [otherTextValues, props.otherSuggestions]);

  // MARK - Live data

  const onContentUpdate = (value) => {
    if (value.length < 2) {
      return;
    }
    const allSuggestions = new Set(props.otherSuggestions).add(value);
    props.onContentUpdate({ otherSuggestions: [...allSuggestions] });
  };

  // MARK - JSX

  const helpText = () => {
    if (props.minSelected === undefined) {
      if (props.maxSelected === undefined) {
        return '';
      }
      return `Please tick up to ${props.maxSelected}`;
    }
    if (props.maxSelected === undefined) {
      if (props.minSelected === 0) {
        return '';
      }
      if (props.minSelected === 1) {
        return `Please tick at least ${props.minSelected}`;
      }
    }
    if (props.minSelected === props.maxSelected) {
      return `Please tick ${props.minSelected}`;
    }
    return `Please tick between ${props.minSelected} and ${props.maxSelected} (inclusive)`;
  };

  return (
    <Form>
      <FormField label={props.label} error={errorMessage} help={helpText()}>
        <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small'>
          {values.map(
            ([key, value], idx) =>
              (key.includes('choice') && (
                <CheckBoxGrommet
                  name={key}
                  key={key}
                  label={props.choices[idx]}
                  checked={value}
                  onChange={onChange}
                />
              )) ||
              (key.includes('other') && (
                <Box direction='row' pad='none' key={key}>
                  <CheckBoxGrommet
                    name={key}
                    checked={value}
                    onChange={onChange}
                    label=<ThemeContext.Extend
                      value={{
                        global: { input: { font: { weight: 'unset' } } },
                      }}
                    >
                      <TextInput
                        name={`${key}-text`}
                        placeholder='Other (please specify)'
                        value={otherTextValues[`${key}-text`]}
                        onChange={onTextInputChange}
                        onSelect={onTextInputSelect}
                        suggestions={otherSuggestions[`${key}-text`]}
                        plain='full'
                      />
                    </ThemeContext.Extend>
                  />
                  <Button
                    plain
                    margin={{ left: 'small' }}
                    icon=<Share size='small' />
                    onClick={() =>
                      onContentUpdate(otherTextValues[`${key}-text`])
                    }
                  />
                </Box>
              ))
          )}
        </Box>
      </FormField>
    </Form>
  );
};

CheckBox.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  minSelected: PropTypes.number,
  maxSelected: PropTypes.number,
  other: PropTypes.number,
  otherSuggestions: PropTypes.arrayOf(PropTypes.string),
  onData: PropTypes.func,
  onContentUpdate: PropTypes.func,
};
