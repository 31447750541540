import React, { useState, useEffect } from 'react';
import { useDeepCompareEffectNoCheck as useEffectDeepCompare } from 'use-deep-compare-effect';
import { Box, Button, Form, FormField, TextInput, TextArea } from 'grommet';
import { Share } from 'grommet-icons';
import PropTypes from 'prop-types';
import Ajv from 'ajv';

const jsonSchemaValidator = new Ajv();

// A question with single or multiple text input fields.
// Supports suggestions.
export const Text = (props) => {
  // MARK - Form handling

  const [validator, setValidator] = useState((state) => () => false);

  useEffectDeepCompare(() => {
    const schema = {
      type: 'array',
      items: {
        type: 'string',
        minLength: props.allowEmpty ? 0 : 1,
      },
      minItems: 1,
      maxItems: props.fieldLabels?.length || 1,
    };
    setValidator((state) => jsonSchemaValidator.compile(schema));
  }, [props.allowEmpty, props.fieldLabels]);

  const [values, setValues] = useState({});

  useEffectDeepCompare(() => {
    const keys = Array.from(
      { length: props?.fieldLabels?.length || 1 },
      (_, idx) => `${props.id}-textInput${idx}`
    );
    const initialValues = Object.fromEntries(keys.map((key) => [key, '']));
    setValues(initialValues);
  }, [props.id, props.fieldLabels]);

  useEffect(() => {
    // Only set values when we have a submit value. e.g. question content will be set again with a broadcast live data update.
    if (props.submit === undefined) {
      return;
    }
    setValues((values) => {
      const previousSubmitValues = validator(props.submit)
        ? props.submit.values()
        : [].values();
      return Object.fromEntries(
        Object.keys(values).map((key) => [
          key,
          previousSubmitValues.next().value || '',
        ])
      );
    });
  }, [props.submit, validator]);

  const [errorMessage, setErrorMessage] = useState('');

  useEffectDeepCompare(() => {
    // Transform values
    //    {textInput0: 'abc', textInput1: 'def', ...}
    // into array of values
    //    ["abc", "def", ...]
    const submitValues = Object.values(values);

    // Validate
    const isValid = validator(submitValues);

    // Pass up to App
    props.onValid(isValid);

    // Act on validation
    // Ideally, this would only display an error if a form field is touched.
    if (isValid) {
      props.onData(submitValues);
      setErrorMessage('');
    } else {
      const messages = validator.errors
        ? validator.errors.map((error) => {
            if (error.schemaPath === '#/minItems') {
              if (error.params.limit === 1) {
                return 'You must supply an answer';
              }
              return `You must supply ${error.params.limit} answers`;
            }
            if (error.schemaPath === '#/items/minLength') {
              if (error.params.limit === 1) {
                return 'Your answer is empty';
              }
              return `You must supply ${error.params.limit} answers`;
            }
            console.warn('Unhandled validation error: ', error);
            return error.message;
          })
        : ['Failed validation'];
      setErrorMessage(messages.join(' · '));
    }
  }, [values]);

  // MARK - Suggestion handling

  const [suggestions, setSuggestions] = useState([]);

  const onTextInputSelect = (event) => {
    const {
      suggestion,
      target: { name },
    } = event;
    setValues((values) => ({ ...values, [name]: suggestion }));
  };

  // Keep suggestions updated from source and filtered from entered text
  useEffect(() => {
    const newSuggestions = Object.entries(values).reduce(
      (acc, [key, value]) => {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const exp = new RegExp(escapedText, 'i');
        const allSuggestions = props.suggestions || [];
        acc[key] = allSuggestions.filter(
          (s) => exp.test(s) && !/^__.+__$/.test(s)
        );
        return acc;
      },
      {}
    );
    setSuggestions(newSuggestions);
  }, [values, props.suggestions]);

  // MARK - Live data

  const onContentUpdate = (value) => {
    if (value.length < 2) {
      return;
    }
    const allSuggestions = new Set(props.suggestions).add(value);
    props.onContentUpdate({ suggestions: [...allSuggestions] });
  };

  // MARK - JSX

  const helpText = () => {
    if (props.fieldLabels && !props.allowEmpty) {
      if (props.fieldLabels.length > 1) {
        return `Please fill out all fields`;
      }
    }
    return '';
  };

  return (
    <Form value={values} onChange={(nextValues) => setValues(nextValues)}>
      <FormField label={props.label} error={errorMessage} help={helpText()}>
        <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small'>
          {Object.entries(values).map(([key, value], idx) => (
            <React.Fragment key={key}>
              <div
                hidden={
                  props.fieldLabels && props.fieldLabels[idx] === '__hide__'
                }
              >
                {props.fieldLabels && (
                  <label htmlFor={key}>{props.fieldLabels[idx]}</label>
                )}
                <Box direction='row' pad='none'>
                  {props.multiline ? (
                    <TextArea name={key} value={value} />
                  ) : (
                    <TextInput
                      name={key}
                      onSelect={onTextInputSelect}
                      suggestions={suggestions[key]}
                    />
                  )}
                  {props.suggestions && (
                    <Button
                      plain
                      margin={{ left: 'small' }}
                      icon=<Share size='small' />
                      onClick={() => onContentUpdate(value)}
                    />
                  )}
                </Box>
              </div>
            </React.Fragment>
          ))}
        </Box>
      </FormField>
    </Form>
  );
};

Text.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  fieldLabels: PropTypes.arrayOf(PropTypes.string),
  allowEmpty: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onData: PropTypes.func,
  onContentUpdate: PropTypes.func,
};
