export const subscriberFactory = (socket, channelName) => async ({
  handler = null,
} = {}) => {
  // Get current
  const rpc = rpcInvokerFactory(socket, channelName);
  rpc({ handler: handler });
  // Subscribe to future updates
  let channel = socket.subscribe(channelName);
  for await (let data of channel) {
    handler === null ? console.log(data) : handler(data);
  }
};

export const unsubscriberFactory = (socket, channelName) => () =>
  socket.unsubscribe(channelName);

export const rpcInvokerFactory = (socket, eventName) => async ({
  payload = {},
  handler = null,
} = {}) => {
  let result = await socket.invoke(eventName, payload);
  handler === null ? console.log(result) : handler(result);
};
