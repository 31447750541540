import { CheckBox } from './CheckBox.js';
import { Quant } from './Quant.js';
import { Text } from './Text.js';

// To set the component type at runtime, get the desired property and assign to a capitalised variable.
// https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
export const questionComponents = {
	CheckBox: CheckBox,
	Quant: Quant,
	Text: Text,
};
