export const namer = (...parts) => parts.join('--');
export const unnamer = (name, ...parts) => {
	const remaining = name.replace(namer(...parts), '');
	if (remaining.startsWith('--')) {
		return remaining.slice(2);
	}
	return remaining;
};
export const nameContains = (name, part) =>
	name.split('--').some((x) => x === part);
