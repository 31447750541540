import React from 'react';
import { Anchor, Box, Heading, Text } from 'grommet';
import PropTypes from 'prop-types';

import { AGClientSocket } from 'socketcluster-client';

const stateFormatter = new Map();
stateFormatter.set(AGClientSocket.CONNECTING, 'connecting');
stateFormatter.set(AGClientSocket.OPEN, 'connected');
stateFormatter.set(AGClientSocket.CLOSED, 'not connected');

export const MSCStatus = (props) => {
	return (
		<Box>
			<Heading>{props.slug || 'No project'}</Heading>
			{!props.socket && <Text>No client</Text>}
			{props.socket && (
				<>
					<Text>Connection: {stateFormatter.get(props.socket.state)}</Text>
					<Text>Dataset ID: {props.datasetID || '-'}</Text>
					<Text>Client ID: {props.socket.clientId}</Text>
				</>
			)}
			<Heading>{props.user?.nickname || 'No user'}</Heading>
			{props.user && (
				<>
					<Text>{props.user.email}</Text>
					<Text>{props.user.sub}</Text>
					<Anchor href='/logout' label='Log out' />
				</>
			)}
		</Box>
	);
};

MSCStatus.propTypes = {
	socket: PropTypes.object,
	slug: PropTypes.string,
	datasetID: PropTypes.string,
};
