import React from 'react';
import { Blank } from 'grommet-icons';

// This wraps `fic - icc symbol 24px.svg`. Went down a rabbit-hole automatically pulling in the svg from `icc-lib` module, but... sod that for now, death by a thousand cuts.
export const IccIcon = (props) => (
	<Blank {...props}>
		<svg viewBox='0 0 24 24' stroke='none' fill='white'>
			<circle cx='6.205' cy='15.228' r='0.712' />
			<path d='M7.827,10.486c-0.119,0.011 -0.24,0.017 -0.361,0.017c-0.057,0 -0.114,-0.001 -0.17,-0.003c-0.005,0.002 -0.01,0.003 -0.015,0.003c-0.866,0.068 -0.806,0.778 -0.456,2.221c0.202,0.834 -1.409,1.054 -1.077,-0.098c0.432,-1.505 0.24,-2.213 -0.411,-2.806l0,-0c-0.925,-0.664 -1.528,-1.749 -1.528,-2.973c0,-1.984 1.583,-3.601 3.554,-3.655c0.714,-1.866 2.522,-3.192 4.637,-3.192c2.115,0 3.923,1.326 4.637,3.192c1.971,0.054 3.554,1.671 3.554,3.655c-0,2.018 -1.638,3.656 -3.657,3.656c-0.121,0 -0.242,-0.006 -0.361,-0.017l-4.173,13.514l-4.173,-13.514Zm7.595,-0.155c-0.531,-0.17 -1.01,-0.458 -1.405,-0.833c-0.616,0.275 -1.299,0.428 -2.017,0.428c-0.718,-0 -1.401,-0.153 -2.017,-0.428c-0.395,0.375 -0.874,0.663 -1.405,0.833l3.422,11.082l3.422,-11.082Z' />
		</svg>
	</Blank>
);
